import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';

const IndexPage = () => (
  <Layout pageTitle="">
    <Link to="/search">Voir le POC Algolia</Link>
    <h2>Catégories</h2>
    <Link to="/c/Salé/Tartes, Quiches et Pizza/Pizza">Pizza</Link>
  </Layout>
);

export default IndexPage;
